<template>
  <div class="checkout-content">
    <div v-if="payOrderInfo">
      <div class="payAmount-content">
        <div class="payAmount-timestamp" v-if="countDownTimestamp">
          订单将在
          <CountDown :time="countDownTimestamp"></CountDown>
          之后关闭，请尽快完成支付
        </div>
        <div class="payAmount-timestamp" v-else> 订单已过期 </div>
        <div class="payAmount-text"> 支付金额(元) </div>
        <div class="payAmount-amount"> ￥{{ payOrderInfo.amount }} </div>
      </div>
      <div class="payInfo">
        <div class="cell-group">
          <div class="warp" v-if="payOrderInfo.ext && payOrderInfo.ext.name">
            <div class="label">姓名</div>
            <div class="value">{{ payOrderInfo.ext.name }}</div>
          </div>
          <div class="warp">
            <div class="label">订单号</div>
            <div class="value">{{ payOrderInfo.mchOrderNo }}</div>
          </div>
          <div class="warp">
            <div class="label">订单名称</div>
            <div class="value">{{ payOrderInfo.subject }}</div>
          </div>
          <div class="warp">
            <div class="label">商户名称</div>
            <div class="value">{{ payOrderInfo.mchName }}</div>
          </div>
        </div>
      </div>
      <div class="payTypes">
        <div class="payTypes-text"> 支付方式 </div>
        <ul class="plus-ul">
          <!-- <template v-for="items in payOrderInfo.payInterface"> -->
            <!-- 支付板块 -->
            <li class="payType" v-for="item in payWayList" :key="item.wayCode" @click="choosePayType(item.wayCode)">
              <!-- 支付金额板块 -->
              <div class="img-div">
                <div class="flex">
                  <img :src="getImgIcon(item.wayCode)" alt="" />
                  <div class="div-text">{{ item.wayName }}</div>
                </div>
                <div class="choose"
                  :style="item.wayCode == wayCode ? `background-color: ${color}` : ''"></div>
              </div>
            </li>
          <!-- </template> -->

        </ul>
      </div>
      <div class="clearfixed"></div>
      <div class="bottom">
        <div class="action">
          <div class="text">
            合计：
            <span class="amount"> ￥{{ payOrderInfo.amount }}元 </span>
          </div>
          <div style="flex: 1">
            <div :style="{ backgroundColor: color }" class="button" type="primary" @click="submit">
              立即支付
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPayOrderInfo, getPayPackage } from "@/api/api";
import config from "@/config";
import CountDown from './count-down.vue'
import wayCodeUtils from "@/utils/wayCode";
import utils from "@/utils/utils";
export default {
  components: {
    CountDown
  },
  data() {
    return {
      payWay: wayCodeUtils.getPayWay().name, 
      value: null,
      emptyLoading: false,
      payOrderInfo: null,
      countDownTimestamp: 0,
      wayCode: '', 
      color: wayCodeUtils.getPayWay().color,
      payWayList: []
    }
  },
  mounted() {
    this.setPayOrderInfo()
  },
  methods: {
    setPayOrderInfo(isAutoPay) {
      this.$loading.show({
        backgroundColor: "rgb(237,237,237)",
      });
      let payOrderNo = config.cachePayParams || this.$route.query.payOrderNo
      getPayOrderInfo(payOrderNo)
        .then((res) => {
          this.payOrderInfo = res.data;
          this.countDownTimestamp = utils.GetExpireTime(
            res.data.createTime,
            res.data.expireTime
          ).number
          if (res.data.payInterface && res.data.payInterface.length) {
            // this.wayCode = res.data.payInterface[0].payWays[0].wayCode
            let list = []
            res.data.payInterface.forEach(item => {
              item.payWays.forEach(items => {
                list.push(items)
              })
            });
            list = wayCodeUtils.getPayWayList(list);
            this.payWayList = list
            this.wayCode = list[0].wayCode
            console.log(list);
          }

          //支付状态: 0-订单生成, 1-支付中, 2-支付成功, 3-支付失败, 4-已撤销, 5-已退款, 6-订单关闭
          if (res.data.status !== 0 && res.data.status !== 1) {
            //订单不是未支付，说明订单异常
            let message;
            switch (res.data.status) {
              case 2:
                message = "已支付";
                break;
              case 3:
                message = "支付失败";
                break;
              case 4:
                message = "已撤销";
                break;
              case 5:
                message = "已退款";
                break;
              case 6:
                message = "订单关闭";
                break;
              default:
                message = "待支付";
                break;

            }
            return alert(message);
          }

          if (isAutoPay) {
            this.submit();
          }
        })
        .catch((err) => {
          this.$router.push({
            name: config.errorPageRouteName,
            params: { errInfo: err.message },
          });
        })
        .finally(() => {
          this.$loading.hide();
        });
    },     
    choosePayType(wayCode) {
      this.wayCode = wayCode
    },
    getImgIcon(wayCode) {
      return wayCodeUtils.getImgIcon(wayCode)
    },
    // 支付事件
    wxpay: function (payOrderNo, wayCode) {
      // 该函数执行效果慢
      let that = this;
      this.$loading.show({
        backgroundColor: "rgba(0, 0, 0, 0)",
      });
      getPayPackage(payOrderNo, wayCode)
        .then((res) => {
          if (res.code != "0") {
            return alert(res.message);
          }
          that.resData = res.data.payData;
          if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
              document.addEventListener(
                "WeixinJSBridgeReady",
                that.onBridgeReady(payOrderNo),
                false
              );
            } else if (document.attachEvent) {
              document.attachEvent("WeixinJSBridgeReady", that.onBridgeReady(payOrderNo));
              document.attachEvent("onWeixinJSBridgeReady", that.onBridgeReady(payOrderNo));
            }
          } else {
            that.onBridgeReady(payOrderNo);
          }
        })
        .catch((err) => {
          that.$router.push({
            name: config.errorPageRouteName,
            params: { errInfo: err.message },
          });
        })
        .finally(() => {
          this.$loading.hide();
        });
    },

    /* 唤醒微信支付*/
    onBridgeReady(payOrderNo) {
      let that = this;
      if (wayCodeUtils.getPayWay().mini) {
        let payDataStr = JSON.stringify({ payData: that.resData, returnUrl: that.payOrderInfo.returnUrl, orderNo: that.payOrderInfo.orderNo });//因为要吧参数传递给小程序，所以这里需要转为字符串
        const url = `../payment/wxpay?payDataStr=${encodeURIComponent(payDataStr)}`;
        // eslint-disable-next-line no-undef
        wx.miniProgram.redirectTo({
          url: url
        });
      } else {
        try {
          // let params = Object.assign(that.resData, { appId: that.resData.appid });

          // eslint-disable-next-line no-undef
          WeixinJSBridge.invoke("getBrandWCPayRequest", that.resData, function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              console.log(res);
            }
            if (res.err_msg == "get_brand_wcpay_request:cancel") {
              alert("支付取消");
              // window.WeixinJSBridge.call("closeWindow");
            }
            if (res.err_msg == "get_brand_wcpay_request:fail") {
              alert("支付失败:" + JSON.stringify(res));
              // window.WeixinJSBridge.call("closeWindow");
            }
            if (res.err_msg == "total_fee") {
              alert("缺少参数");
              // window.WeixinJSBridge.call("closeWindow");
            }
            that.$router.push({
              name: "ReturnWxpay",
              query: { payOrderNo: payOrderNo },
            });
          });
        } catch (error) {
          console.log(error);
        }
      }
    },

    alipay: function (payOrderNo, wayCode) {
      let that = this;
      this.$loading.show({
        backgroundColor: "rgba(0, 0, 0, 0)",
      });
      getPayPackage(payOrderNo, wayCode)
        .then((res) => {
          //订单创建异常
          if (res.code != "0") {
            return alert(res.message);
          }

          // if (res.data.orderState != 1) {
          //   //订单不是支付中，说明订单异常
          //   return alert(res.data.errMsg);
          // }

          if (!window.AlipayJSBridge) {
            document.addEventListener(
              "AlipayJSBridgeReady",
              function () {
                that.doAlipay(res.data.payData.orderStr, payOrderNo);
              },
              false
            );
          } else {
            that.doAlipay(res.data.payData.orderStr, payOrderNo);
          }
        })
        .catch((err) => {
          that.$router.push({
            name: config.errorPageRouteName,
            params: { errInfo: err.message },
          });
        })
        .finally(() => {
          this.$loading.hide();
        });
    },

    doAlipay(orderStr, payOrderNo) {
      let that = this;

      // eslint-disable-next-line no-undef
      window.AlipayJSBridge.call(
        "tradePay",
        {
          orderStr: orderStr,
        },
        function (data) {
          console.log(data, 'data', orderStr);
          if ("9000" == data.resultCode) {
            // alert('支付成功！');

            // //重定向
            // if (that.payOrderInfo.returnUrl) {
            //   location.href = that.payOrderInfo.returnUrl;
            // } else {
            //   alert("支付成功！");
            //   window.AlipayJSBridge.call("closeWebview");
            // }

            //‘8000’：后台获取支付结果超时，暂时未拿到支付结果;
            // ‘6004’：支付过程中网络出错， 暂时未拿到支付结果;
          } else if ("8000" == data.resultCode || "6004" == data.resultCode) {
            //其他

            alert(JSON.stringify(data));
            // window.AlipayJSBridge.call("closeWebview");
          } else {
            ///其他异常信息， 需要取消订单
            alert("用户已取消！");
            // window.AlipayJSBridge.call("closeWebview");
          }
          that.$router.push({
            name: "ReturnAlipay",
            query: { payOrderNo: payOrderNo },
          });
        }
      );
    },
    /**
     * 提交
     * */
    submit() {
      //if (wayCodeUtils.getPayWay().name == 'wx') {
      if (this.wayCode.includes('WX')) {
        this.wxpay(config.cachePayParams, this.wayCode)
      } else {
        this.alipay(config.cachePayParams, this.wayCode)
      }
    },
  },
}
</script>

<style lang="css" scoped>
.checkout-content .payAmount-content {
  text-align: center;
}

.checkout-content .payAmount-content .payAmount-timestamp {
  color: #797c81;
  background: #fdf6ec;
  border-top: 1px solid #ff9900;
  border-bottom: 1px solid #ff9900;
  padding: 4px 0;
  margin: 2px 0 25px;
  font-size: 28px;
}

.checkout-content .payAmount-content .payAmount-text {
  font-size: 28px;
  margin-bottom: 10px;
}

.checkout-content .payAmount-content .payAmount-amount {
  font-size: 50px;
  color: #e8791b;
}

.checkout-content .payInfo .warp {
  font-size: 28px;
  padding: 26px 30px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout-content .payTypes {
  margin: 18px 0;
}

.checkout-content .payTypes .payTypes-text {
  font-size: 30px;
  padding: 10px 24px;
  color: #283989;
  text-align: left;
}

.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #ffffff;
  width: 100%;
}

.bottom .action {
  display: flex;
  justify-content: space-around;
  height: 100px;
  border-top: 1px solid #f2f2f2;
}

.bottom .action .text {
  font-size: 30px;
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom .action .amount {
  color: red;
  font-weight: 600;
}

.bottom .action .button {
  font-size: 30px;
  line-height: 100px;
  color: #fff;
  background-color: #04d367;
  width: 100%;
  height: 100%;
}

.plus-ul {
  margin-top: 20px;
  margin-bottom: 0;
  padding: 0;
  width: 100%;
}

.plus-ul li {
  border-bottom: 1px solid #eee;
  position: relative;
  padding: 38px 30px;
  display: flex;
  justify-content: space-between;
}

.plus-li {
  margin-bottom: 20px;
  border-radius: 15px;
}

.img-div {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.img-div .flex {
  display: flex;
}

.img-div img {
  width: 42px;
  height: 42px;
  margin-right: 30px;
}

.img-div .div-text {
  height: 42px;
  font-size: 30px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: left;
  color: #323232;
  letter-spacing: 1px;
}

.choose {
  width: 40px;
  height: 40px;
  background-color: #dadada;
  border-radius: 50%;
  position: relative;
}

.choose::after {
  content: '';
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
}

.clearfixed {
  padding-bottom: 100px;
}
</style>
